import React from 'react';
// Import Swiper React components
import { Autoplay, Pagination } from "swiper";
// import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

import propic from '../img/propic1.jpg';

export default function Testimonial() {
    return (
        <div className="page-style testimonial ">
            <div className="page-description">
                <div id="testimonial">
                    {/* <!-- Section Title --> */}
                    <div className="section-title">
                        <h1>Testimonial</h1>
                    </div>
                    {/* <!-- Tag --> */}
                    <div className="tag">
                        <h4>Testimonial</h4>
                    </div>
                    {/* <!-- Content --> */}
                    <div className="content">
                        <div className="testimonial-content">
                            {/* <!-- Testimonial Intro --> */}
                            <div className="testimonial-intro sub-text">
                                <h4 className="sub-heading">TESTMONIALS</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque alias libero, laudantium minus autem, sunt voluptates voluptatem laborum sint, atque magnam eum quis asperiores maiores!</p>
                            </div>

                            <Swiper
                                spaceBetween={10}
                                slidesPerView={1}
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}

                                breakpoints={{
                                    575: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    1199: {
                                        slidesPerView: 2,
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                    }
                                }}
                                modules={[Autoplay, Pagination]}

                            >
                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>Nazmul is a great seller, very talented and kind. He delivered the result in very short time without ignoring the quality. Responsive for any revision and solved it quickly if i have problem. Thanks a lot.</p>
                                            <h4>aylansemesta</h4>
                                            {/* <h5>Indonesia</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>Nazmul is very professional and simply gets things done. We have been working with Nazmul for almost three years now on a regular basis and we could not be happier!</p>
                                            <h4>Levan</h4>
                                            {/* <h5>Germany</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>Very skilled coder. Our project was delivered on time exactly as promised. Communication was very efficient, no handholding was required. Would definitely recommend this freelancer to anyone looking to have coding work done. Thank you!</p>
                                            <h4>Neiara</h4>
                                            {/* <h5>Saudi Arabia</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>Good communication skills, understood the assignment and the first delivered version was already without any issues. Definitely a recommendation. Thank you!</p>
                                            <h4>adsolvix</h4>
                                            {/* <h5>Germany</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>A true professional in the field - I highly recommend Naz as a skilled developer with great communication skills and a perfect delivery record - I have worked with Naz on over 10 projects and he always exceeds my expectations</p>
                                            <h4>ALSTP</h4>
                                            {/* <h5>Australia</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>We needed a new view for our dashboard and nazmul18 handled the gig very good. We'll certainly get back to him in case we have another similar job.</p>
                                            <h4>FABIAN GOEDLE</h4>
                                            {/* <h5>Australia</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>Seller is fantastic to work with, fixes every issue in a very timely manor and without hesitation. I really appreciated working with him to complete my new website and I am very happy with the results. Highly recommend working with him</p>
                                            <h4>FRESH FARM INC</h4>
                                            {/* <h5>Australia</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="single-testimonial">
                                        <div className="single-testimonial-profile">
                                            <img src={propic} alt="propic" />
                                            <i className="thenazmulh-quote"></i>
                                        </div>
                                        <div className="single-testimonial-text">
                                            <p>Nazmul is great he is very attentive to detail and also very patient when I asked for a couple of revisions (which were my mistake). I would highly recommend him.</p>
                                            <h4>PODISNAK</h4>
                                            {/* <h5>Australia</h5> */}
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>

                        </div>
                        {/* <!-- Clients  --> */}
                        {/* <div className="clients-area main-content">
                            <h4 className="sub-heading">CLIENTS</h4>
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={2}
                                breakpoints={{
                                    575: {
                                        slidesPerView: 3,
                                    },
                                    991: {
                                        slidesPerView: 4,
                                    }
                                }}
                            >
                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients1} alt="clients" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients2} alt="clients" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients3} alt="clients" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients4} alt="clients" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients5} alt="clients" />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients2} alt="clients" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients3} alt="clients" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-clients">
                                        <img src={clients4} alt="clients" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
