const Projects = [
  {
    "id": 1,
    "name": "journee",
    "description": "Journee | A Flight booking website in HTML, CSS and JS",
    "thumbnail": "https://i.imgur.com/8292iVC.png",
    "category": "travel",
    "url": "http://www.nazmul.me/projects/#journee",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 2,
    "name": "traders",
    "description": "Traders | LEARN HOW TO TRADE LIKE A PRO",
    "thumbnail": "https://i.imgur.com/Oq5RBoc.png",
    "category": "education",
    "url": "http://www.nazmul.me/projects/#traders",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 3,
    "name": "propertypro",
    "description": "Propertypro | Property Management System",
    "thumbnail": "https://i.imgur.com/w312JjK.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#propertypro",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 4,
    "name": "stackunion",
    "description": "United We Stake.",
    "thumbnail": "https://i.imgur.com/4xXaqef.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#stackunion",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 5,
    "name": "studywithoutborder",
    "description": "In a world where ideas can cross oceans with a click of a button...",
    "thumbnail": "https://i.imgur.com/ALpEtbL.jpg",
    "category": "education",
    "url": "http://www.nazmul.me/projects/#studywithoutborder",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 6,
    "name": "stories",
    "description": "TRAVEL SOCIETY",
    "thumbnail": "https://i.imgur.com/N2KAmp3.jpg",
    "category": "travel",
    "url": "http://www.nazmul.me/projects/#stories",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },

  {
    "id": 7,
    "name": "potato",
    "description": "Chat to a Potato about your life problems",
    "thumbnail": "https://i.imgur.com/ipocQ4m.png",
    "category": "entertainment",
    "url": "http://www.nazmul.me/projects/#potato",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 8,
    "name": "groupo",
    "description": "Groupo",
    "thumbnail": "https://i.imgur.com/K2jboFD.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#groupo",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },

  {
    "id": 9,
    "name": "unifans",
    "description": "Unifans | Unite with Fans You Need",
    "thumbnail": "https://i.imgur.com/lGmfwXD.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#unifans",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },

  {
    "id": 10,
    "name": "v",
    "description": "v | Shpping made easy",
    "thumbnail": "https://i.imgur.com/XPFxbjY.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#v",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },

  {
    "id": 11,
    "name": "teleafya",
    "description": "Teleafya",
    "thumbnail": "https://i.imgur.com/ALDHdXj.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#teleafya",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 12,
    "name": "funneljoy",
    "description": "Build Better Marketing Funnels",
    "thumbnail": "https://i.imgur.com/0pJ5UGq.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#funneljoy",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 13,
    "name": "opentrof",
    "description": "YOUR PLATFORM FOR UNCENSORED SUBSCRIPTION BASED VIDEO DELIVERY",
    "thumbnail": "https://i.imgur.com/kBTfYwm.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#opentrof",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },
  {
    "id": 14,
    "name": "brand",
    "description": "BRAND EVENT PLANNING",
    "thumbnail": "https://i.imgur.com/1HY8ZbP.png",
    "category": "business",
    "url": "http://www.nazmul.me/projects/#brand",
    "details": {
      "technologies": ["HTML", "CSS", "JavaScript"],
      "date": "",
      "client": "",
      "images": [],
      "description": ""
    }
  },

]

export default Projects;