import { motion } from 'framer-motion';
import React from 'react';
import TextTransition, { presets } from "react-text-transition";
// import homeillustration from "../img/home.svg";

// import nazmul from "../img/nazmul.png";
const TEXTS = [
  "Develoeper",
  "TRS at fiverr",
  "Traveller",
  "CSE Graduate",
];
const Home = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div className="page-style home ">
      <div className="page-description">
        <div id="home">
          {/* <!-- Tag --> */}
          <div className="tag">
            <h4>Home</h4>
          </div>
          {/* <!-- Home illustration --> */}
          <div className="home-illustration">

            {/* <img src={nazmul} alt="" /> */}
            {/* <img src={homeillustration} alt="homeillustration" /> */}
          </div>
          {/* <!-- Content --> */}
          <motion.div initial={{ opacity: .1 }} animate={{ opacity: 1 }} className="content home-content">
            <h2>Hello,</h2>
            <h1 className="cd-headline clip is-full-width">
              <span>I am a </span>

              <TextTransition springConfig={presets.gentle} inline={true} className="theme-color">
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
            </h1>

            <p>I am a passionate coder and tech enthusiast hailing from the picturesque country of Bangladesh. With a deep love for coding and a strong work ethic, I am dedicated to creating innovative and high-quality solutions for my clients and constantly striving to improve my skills.</p>
            {/* <!-- <div className="home-links mt-40">
                        <a href="#" className="link theme-bg active">Hire Me</a>
                        <a href="#" className="link ml-15">Download CV</a>
                    </div> --> */}
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Home