import { motion } from 'framer-motion';
import React from 'react';
import img1 from "./../../img/portfolio/img-1.jpg";

const SingleProject = ({ items }) => {
    const allProjects = items.map(singleProject => {
        return (

            <motion.div layout
                key={singleProject.id}
                initial={{ opacity: 0, scale: .95 }}
                exit={{ opacity: 0, scale: 1 }}
                animate={{ opacity: .7, scale: 1 }}
                className="item brand graphic projects">
                {/* <!-- Project Item Start --> */}
                <div className="item" data-project-img={img1}
                    data-project-link="#">
                    <figure>
                        <img src={singleProject.thumbnail} alt="book design" />

                        <figcaption>
                            <h4>{singleProject.name}</h4>
                            <p>{singleProject.description}</p>
                            <a target='_blank' href={singleProject.url} className="live-link" rel="noreferrer">Live Link ~~&gt;</a>
                        </figcaption>
                    </figure>
                    {/* <!-- Project Details --> */}
                    <div className="project-details">
                        <span>Project Type: </span> PSD To Responsive HTML <br />
                        <span>Web Technologies: </span> {singleProject.details.technologies.map(st => <span>{st} </span>)}<br />
                        <span>Number of pages:</span> 3 <br />
                        <p>{singleProject.details.description}</p>
                    </div>
                </div>
            </motion.div>

        )

    })

    return (
        <>
            {allProjects}
        </>
    )
}

export default SingleProject