import React from 'react';
import { useLoaderData, useParams } from 'react-router-dom';

export default function BlogDetails() {
    const params = useParams();
    const blogD = useLoaderData();
    console.log(blogD);
    return (
        <div className="page-style portfolio">
        <div className="page-description">
            <div id="portfolio">
                {/* <!-- Section Title --> */}
                <div className="section-title">
                    <h1>Blog</h1>
                </div>
                {/* <!-- Tag --> */}
                <div className="tag">
                    <h4>Blog Details</h4>
                </div>
                {/* <!-- Content --> */}
                
                <div className="content portfolio-content">
                    {/* <!--Porfolio Area--> */}
                    <div id="portfolio" className="portfolio-area">
                        <div className="section-container">
          
                        <h1>Blog { params.id }</h1>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


  
export const blogDetailsLoader = async ({ params }) => {
    const { id } = params
  
      const res = await fetch('https://dummyjson.com/products/1' + id)
      
      if (!res.ok) {
          throw Error("Could not load Career");
      }
      
  
    return res.json()
  }