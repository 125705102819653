import React from 'react'

const About = () => {
    return (
        <div className="page-style about ">
            <div className="page-description">
                <div id="about">
                    {/* <!-- Section Title --> */}
                    <div className="section-title">
                        <h1>About</h1>
                    </div>
                    {/* <!-- Tag --> */}
                    <div className="tag">
                        <h4>About</h4>
                    </div>
                    {/* <!-- Content --> */}
                    <div className="content about-content">
                        {/* <!-- About --> */}
                        <div className="about-top">
                            {/* <!-- About Left --> */}
                            <div className="about-left">
                                {/* <!-- About Me --> */}
                                <div className="about-me sub-text">
                                    <h4 className="sub-heading">ABOUT ME</h4>
                                    <p>My name is Nazmul Hussain and I am a highly skilled and experienced web developer based in Bangladesh. As a freelancer, I have the flexibility to take on projects from clients all over the world. I am also proficient in both MacOSX and Linux operating systems. </p>

                                    <p>I am a top-rated seller on Fiverr, known for my exceptional work and commitment to client satisfaction.</p>

                                    <p> In my personal life, I am a devoted husband and father to a beautiful child. </p>
                                </div>
                            </div>
                            {/* <!-- About Right --> */}
                            <div className="about-right">
                                <h4 className="sub-heading">MY PERSONAL DETAILS</h4>
                                {/* <!-- Personal Information --> */}
                                <div className="about-personal">
                                    <table>
                                        <tbody>
                                            {/* <!-- Single row --> */}
                                            <tr>
                                                <td className="first-td">Species</td>
                                                <td className="divider">:</td>
                                                <td className="last-td">H. Sapiens</td>
                                            </tr>
                                            {/* <!-- Single row --> */}
                                            <tr>
                                                <td className="first-td">Name</td>
                                                <td className="divider">:</td>
                                                <td className="last-td">Nazmul Hussain</td>
                                            </tr>
                                            {/* <!-- Single row --> */}
                                            <tr>
                                                <td className="first-td">Address</td>
                                                <td className="divider">:</td>
                                                <td className="last-td">Kushtia, Bangladesh</td>
                                            </tr>
                                            {/* <!-- Single row --> */}
                                            <tr>
                                                <td className="first-td">Birthday</td>
                                                <td className="divider">:</td>
                                                <td className="last-td">18, January</td>
                                            </tr>
                                            {/* <!-- Single row --> */}
                                            <tr>
                                                <td className="first-td">Username</td>
                                                <td className="divider">:</td>
                                                <td className="last-td">theNazmulH</td>
                                            </tr>
                                            {/* <!-- Single row --> */}
                                            {/* <!-- <tr>
                                        <td className="first-td">Website</td>
                                        <td className="divider">:</td>
                                        <td className="last-td">www.nazmul.me</td>
                                    </tr> --> */}
                                            {/* <!-- Single row --> */}
                                            <tr>
                                                <td className="first-td">To Discuss</td>
                                                <td className="divider">:</td>
                                                <td className="last-td">
                                                    <a href="http://t.me/theNazmulH">Tap Tap</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <!--================================ Service =================================  --> */}
                        <div className="service main-content">
                            {/* <!-- Title --> */}
                            <h4 className="sub-heading">SERVICE</h4>
                            {/* <!-- Services --> */}
                            <div className="services">
                                {/* <!-- Single Services --> */}
                                <div className="single-services text-center">
                                    <div className="services-inner">
                                        {/* <div className="service-icon">
                                    <i className="thenazmulh-service-ui"></i>
                                </div> */}
                                        {/* <h3>Bug Fixing</h3> */}
                                        <p>Figma/XD/PSD/Sketch To <br /> Responsive HTML</p>
                                        {/* <p>100% Responsive Design</p> */}
                                    </div>
                                </div>
                                {/* <!-- Single Services --> */}
                                <div className="single-services text-center">
                                    <div className="services-inner">
                                        {/* <div className="service-icon">
                                            <i className="thenazmulh-service-ux"></i>
                                        </div> */}
                                        {/* <h3>UX To HTML</h3> */}
                                        <p>Figma/XD/PSD/Sketch To <br /> Responsive React App</p>
                                    </div>
                                </div>
                                {/* <!-- Single Services --> */}
                                <div className="single-services text-center">
                                    <div className="services-inner">
                                        {/* <div className="service-icon">
                                            <i className="thenazmulh-service-ut"></i>
                                        </div> */}
                                        {/* <h3>HTML to React</h3> */}
                                        <p>HTML To <br /> Responsive React App</p>
                                    </div>
                                </div>
                                {/* <!-- Single Services --> */}
                                <div className="single-services text-center">
                                    <div className="services-inner">
                                        {/* <div className="service-icon">
                                            <i className="thenazmulh-service-prototyping"></i>
                                        </div> */}
                                        {/* <h3>Responsive</h3> */}
                                        <p>React App with  <br /> Firebase integration</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About