import React from 'react'

const Education = () => {
    return (
        <div className="page-style educations ">
            <div className="page-description">
                <div id="educations">
                    {/* <!-- Section Title --> */}
                    <div className="section-title">
                        <h1>Educations</h1>
                    </div>
                    {/* <!-- Tag --> */}
                    <div className="tag">
                        <h4>Educations</h4>
                    </div>
                    {/* <!-- Content --> */}
                    <div className="content portfolio-content">
                        {/* <!-- Educations Intro --> */}
                        <div className="portfolio-intro sub-text">
                            <h4 className="sub-heading">Educations</h4>
                            <p>As a Computer Science graduate, I have gained a strong foundation in programming, computer systems, and software engineering.
                                <br />
                                <br />
                                In addition to my coursework, I have 5 years + experience in web developement. These experiences have given me valuable skills in problem-solving, teamwork, and project management.
                                I am constantly seeking to improve and expand my knowledge, and I am excited to continue learning and growing as a professional in the field of Web Developement. </p>
                        </div>
                        {/* <!-- Education --> */}
                        <div className="education-details main-content">
                            <div className="education-institute">

                                {/* <!-- institute Single --> */}
                                <div className="single-institute">
                                    <h4>PRIMARY SCHOOL DEGREE</h4>
                                    <h5>Nastipur Govt. Primary School</h5>
                                    <p>Class 1 to 5</p>
                                </div>
                                {/* <!-- institute Single --> */}
                                <div className="single-institute">
                                    <h4>HIGH SCHOOL DEGREE</h4>
                                    <h5>Memnagar B.D High School</h5>
                                    <p>Class 6 to 10</p>
                                </div>
                                {/* <!-- institute Single --> */}
                                <div className="single-institute">
                                    <h4>COLLEGE DEGREE</h4>
                                    <h5>Darshana Govt. College</h5>
                                    <p>intermediate of science</p>
                                </div>
                                {/* <!-- institute Single --> */}
                                <div className="single-institute">
                                    <h4>BACHELOR DEGREE </h4>
                                    <h5>Islamic University, Bangladesh</h5>
                                    <p>Computer Science & Engineering</p>
                                </div>
                                {/* <!-- institute Single --> */}
                                <div className="single-institute">
                                    <h4>MASTER DEGREE </h4>
                                    <h5>Islamic University, Bangladesh</h5>
                                    <p>Computer Science & Engineering</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Education