import React from 'react'

const LangSkills = (props) => {
    return (
        <li>
        <h5>{props.lang}</h5>
            <div class="rating" data-rat={props.rating}>
          {
            
            
                    [...Array(Number(props.rating))].map((elementInArray, index) => ( 
                          <span  key={index} class="lang-active"></span>
                    ))
          }
           {
            
            
            [...Array(10 - Number(props.rating))].map((elementInArray, index) => ( 
                  <span  key={index} class="lang-inactive"></span>
            ))
        }
            </div>
    </li>
  )
}

export default LangSkills