import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider
} from "react-router-dom";
import RootLayout from "./layout/RootLayout";
import About from "./pages/About";
import Blog, { blogLoader } from "./pages/Blog/Blog";
import BlogDetails, { blogDetailsLoader } from "./pages/Blog/BlogDetails";
import Education from "./pages/Education";
import Experience from "./pages/Experience";
import Home from "./pages/Home";
// import NotFound from "./pages/NotFound";
import Project from "./pages/Projects/Project";
import Skills from "./pages/Skills";
import Testimonial from "./pages/Testimonial";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" exact element={<RootLayout />}>
			<Route index exact element={<Home />} />
			<Route path="about" element={<About />} />
			<Route path="skills" element={<Skills />} />
			<Route path="education" element={<Education />} />
			<Route path="portfolio" element={<Project />} />
			{/* <Route path="portfolio">
				<Route index element={<Project />} />
				<Route
					path=":id"
					element={<Singleproject />}
					loader={ProjectDetailsLoader}
				/>
				<Route path="single" element={<ProjectDetails />} />
			</Route> */}
			<Route path="testimonial" element={<Testimonial />} />
			<Route path="experience" element={<Experience />} />

			<Route path="blog">
				<Route index loader={blogLoader} element={<Blog />} />
				<Route
					path=":id"
					loader={blogDetailsLoader}
					errorElement={<Blog />}
					element={<BlogDetails />}
				/>
			</Route>
			{/* <Route path="*" element={<NotFound />} /> */}
		</Route>
	)
);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
