import React from 'react'
import { Link } from 'react-router-dom'
import blog1 from "../../img/blog/blog-1.jpg"

const Blog = () => {
    return (
        <div className="page-style blog">
            <div className="page-description">
                {/* <!-- Section Title --> */}
                <div className="section-title">
                    <h1>Blogs</h1>
                </div>
                {/* <!-- Tag --> */}
                <div className="tag">
                    <h4>Blogs</h4>
                </div>
                <div id="blog">
                    {/* <!-- Content --> */}
                    <div className="content blog-content">
                        <div className="blog-content">
                            {/* <!-- Testimonial Intro --> */}
                            <div className="blog-intro sub-text">
                                <h4 className="sub-heading">Blogs</h4>
                            </div>
                            <div className="BlogContainer">
                                <div id="theSidebar" className="blogsidebar">
                                </div>
                                <div id="theGrid" className="main">
                                    <div id="wrapper">
                                        <div id="stuff-filters">
                                            <label id="clear-filters" className="active"><input type="radio"
                                                name="filters" />All</label>
                                            <label><input type="radio" name="filters" value="music" className="filter-item" />
                                                Music</label>
                                            <label><input type="radio" name="filters" value="video" className="filter-item" />
                                                Video</label>
                                            <label><input type="radio" name="filters" value="fun" className="filter-item" />
                                                Fun</label>
                                            <label><input type="radio" name="filters" value="web-development"
                                                className="filter-item" /> Web</label>
                                            <label><input type="radio" name="filters" value="tech" className="filter-item" />
                                                Technology</label><br />
                                        </div>
                                    </div>
                                    <div className="grid" id="products">
                                        {/* <!-- Single Blog --> */}
                                        <div className="item grid__item fun music">
                                            <div className="single-blog">
                                                <div className="single-blog-profile">
                                                    <Link to="/"><img src={blog1} alt="blogimage" /></Link>
                                                    {/* <!-- Blog Icon --> */}
                                                    <div className="blog-icon">
                                                        <i className="thenazmulh-music-note-streamline"></i>
                                                    </div>
                                                </div>
                                                {/* <!-- Blog Text --> */}
                                                <a href="#" className="single-blog-text">
                                                    <h5><Link to="/"><i>Music & Fun</i></Link></h5>
                                                    <h4>Current work space</h4>
                                                    <p>The first thing to remember about success is that it is a process.
                                                    </p>
                                                </a>
                                                {/* <!-- lOADER --> */}
                                                <div className="blogloader"></div>
                                                <div className="blog-date">
                                                    <h5><a href="#"><i className="thenazmulh-calendar"></i>14 Dec 18</a></h5>
                                                </div>
                                                {/* <!-- Readmore Link --> */}
                                                <div className="blog-reading-time">
                                                    <a href="#"><i className="thenazmulh-alarm-clock-alt"></i>2 mins read</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog


export const blogLoader = () => {
    return (
        <div>Blog hihi</div>
    )
}