import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import ProPic from "./../img/propic.jpg";
const RootLayout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }
    const handleSidebarMob = () => {
        if (window.innerWidth < 992) {
            setIsSidebarOpen(!isSidebarOpen);
        }

    }
    return (
        // <!--  Sidebar  -->
        <>
            <div className={`${isSidebarOpen} sidebar`}>
                {/* <!-- Toggle Bar --> */}
                <div className="toggle-bar" onClick={handleSidebar}>
                    {/* <!-- <img src="assets/img/menu.svg" alt="toggle navigation"> --> */}
                    <div className="hamburger" id="hamburger">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                    {/* <!-- ./hamburger --> */}
                </div>
                {/* <!-- Profile Picture & Intro --> */}
                <div className="intro">
                    <div className="intro-pic">
                        <img src={ProPic} alt="Profile" />
                    </div>
                    <div className="intro-text">
                        <h3>N<span>azmul</span> H</h3>
                        <h5><span className="theme-color">Frontend Developer </span></h5>
                    </div>
                    {/* <div className="social-links">
                <!-- <a href="www.facebook.com/thenazmulh"><i className="thenazmulh-social-facebook"></i></a> -->
                <!-- <a href="www.twitter.com/thenazmulh"><i className="thenazmulh-social-twitter"></i></a> -->
            </div> */}
                </div>
                {/* <!-- Navigation --> */}
                <div id="nav">
                    <ul onClick={handleSidebarMob}>
                        <NavLink className="btn_nav home_link" to="/" >
                            <li>
                                {/* <i className="thenazmulh-nav-home"></i> */}
                                <strong>#</strong>
                                <span>Home</span></li>
                        </NavLink>
                        <NavLink to="about"><li className="btn_nav about_link">
                            {/* <i className="thenazmulh-nav-about"></i> */}
                            <strong>#</strong>
                            <span>About</span></li></NavLink>
                        <NavLink to="skills">
                            <li className="btn_nav skills_link">
                                {/* <i className="thenazmulh-arrow-streamline-target"></i> */}
                                <strong>#</strong>
                                <span>Skills</span></li>
                        </NavLink>
                        <NavLink to="education">
                            <li className="btn_nav educations_link">
                                {/* <i className="thenazmulh-resume"></i> */}
                                <strong>#</strong>
                                <span>Education</span></li>
                        </NavLink>

                        <NavLink to="portfolio">
                            <li className="btn_nav portfolio_link">
                                {/* <i className="thenazmulh-nav-portfolio"></i> */}
                                <strong>#</strong>
                                <span>Portfolio</span></li>
                        </NavLink>

                        <NavLink to="testimonial">
                            <li className="btn_nav testimonial_link">
                                {/* <i className="thenazmulh-nav-testimonial"></i> */}
                                <strong>#</strong>
                                <span>Testimonial</span></li>
                        </NavLink>


                        <NavLink to="experience">
                            <li className="btn_nav experience_link">
                                {/* <i className="thenazmulh-nav-experience"></i> */}
                                <strong>#</strong>
                                <span>Experience</span></li>
                        </NavLink>

                        {/* <i className="thenazmulh-list"></i> */}
                        {/* <NavLink to="blog">
                            <li className="btn_nav blog_link">
                                
                                <strong>#</strong>
                                <span>Blog</span></li>
                        </NavLink> */}







                        {/* <!-- <li className="btn_nav contact_link"> <i className="thenazmulh-id-card-alt"></i><span>Reach</span></li> --> */}
                    </ul>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default RootLayout