import React from 'react';
import SkillBar from 'react-skillbars';
import LangSkills from '../components/LangSkills';

const Skills = () => {
    const skills = [
        { type: "HTML5", level: 95 },
        { type: "Bootstrap", level: 95 },
        { type: "Javascript", level: 80 },
        { type: "Tailwind", level: 80 },
        { type: "React", level: 80 },
        { type: "Redux", level: 60 },
        { type: "MongoDB", level: 60 },
        { type: "Express.js", level: 60 },
        { type: "NodeJS", level: 60 },
        { type: "CSS3", level: 95 },
        { type: "SASS", level: 90 },
        { type: "jQuery", level: 85 },
        { type: "Wordpress", level: 40 },
    ];
    const colors = {
        "title": {
            "text": "#fff",
            "background": "transparent"
        }
    }
    return (
        <div className="page-style skills">
            <div className="page-description">
                <div id="skills">
                    {/* <!-- Section Title --> */}
                    <div className="section-title">
                        <h1>Skills</h1>
                    </div>
                    {/* <!-- Tag --> */}
                    <div className="tag">
                        <h4>Skills</h4>
                    </div>
                    {/* <!-- Content --> */}
                    <div className="content skills-content">
                        {/* <!-- Skills Intro --> */}
                        <div className="skills-intro sub-text">
                            <h4 className="sub-heading">MY SKILLS</h4>
                            <p>With a diverse set of skills and a wealth of experience under my belt, I am a versatile and highly skilled web developer. My expertise encompasses a wide range of technologies including HTML, CSS, Bootstrap,Tailwind, SASS, SCSS, Javascript, jQuery,  React , Redux, MongoDB, ExpressJS, NodeJS and more. I also have a basic foundation in PHP, C, Wordpress, Laravel and other technologies. My ability to adapt and stay current on the latest tools and trends makes me an excellent choice for any project. You can trust that I will deliver high-quality work, on time and within budget, ensuring complete satisfaction on every project I undertake.</p>
                        </div>
                        {/* <!-- PROFESSIONAL SKILLS  --> */}
                        <div className="pro-skills main-content">
                            <h4 className="sub-heading">PROFESSIONAL SKILLS</h4>

                            <div className="skills">
                                <SkillBar skills={skills} colors={colors} className="skill" />
                            </div>


                        </div>

                        {/* <!-- LANGUAGE SKILL  --> */}
                        <div className="pro-skills">
                            {/* <!-- Skills Column --> */}
                            <div className="skills-column">
                                <div className="skills-intro sub-text">
                                    <h4 className="sub-heading">LANGUAGE SKILL</h4>
                                    <p>As a native speaker of Bengali, I am also fluent in English and can effectively use it for communication purposes.</p>
                                </div>
                                <div className="lang-skills-area">
                                    <div className="skills-intro sub-text">
                                        <h4 className="sub-heading">LANGUAGES</h4>
                                        <ul className="lang-skills">
                                            <LangSkills lang="English" rating="7" />
                                            <LangSkills lang="Bangla" rating="9" />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills