import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import Projects from "../../data/allprojects";
import SingleProject from './SingleProject';

const Project = () => {

    const [project, setProject] = useState(Projects);
    const menuItems = [...new Set(Projects.map((Val) => Val.category))];
    const filterItem = (curcat) => {
        const newItem = Projects.filter((newVal) => {
            return newVal.category === curcat;
        });
        setProject(newItem);
    };
    return (
        <div className="page-style portfolio">
            <div className="page-description">
                <div id="portfolio">
                    {/* <!-- Section Title --> */}
                    <div className="section-title">
                        <h1>Portfolio</h1>

                    </div>
                    {/* <!-- Tag --> */}
                    <div className="tag">
                        <h4>Portfolio</h4>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab exercitationem neque veniam? Recusandae odit beatae, quam facere quibusdam vel facilis.</p> */}
                    </div>
                    {/* <!-- Content --> */}
                    <div className="content portfolio-content">
                        <p className='text-center max-port'>I would be delighted to share some samples created using HTML, CSS/SCSS/SASS, jQuery, JavaScript, and Bootstrap/Tailwind CSS. These samples showcase my skills and expertise in front-end web development.</p>
                        <section id="portfolio" className="portfolio-area">
                            <div className="section-container">
                                {/* <Buttons
                                    filterItem={filterItem}
                                    setItem={setProject}
                                    menuItems={menuItems}
                                /> */}
                                <motion.div layout className="portfolio-items" animate={{ y: 100 }}>
                                    <AnimatePresence>
                                        <SingleProject items={project} />
                                    </AnimatePresence>
                                </motion.div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Project