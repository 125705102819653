import React from 'react'

const Experience = () => {
    return (
        <div className="page-style experience">
            <div className="page-description">
                <div id="experience">
                    {/* <!-- Section Title --> */}
                    <div className="section-title">
                        <h1>Experience</h1>
                    </div>
                    {/* <!-- Tag --> */}
                    <div className="tag">
                        <h4>Experience</h4>
                    </div>
                    {/* <!-- Content --> */}
                    <div className="content experience-content">
                        {/* <!-- Testimonial Intro --> */}
                        <div className="experience-intro sub-text">
                            <h4 className="sub-heading">WORK EXPERIENCE</h4>
                            <p>With over 5 years of experience in web development and a proven track record of delivering exceptional results, I am a seasoned professional in the field. My skills and expertise are reflected in my top-rated status on Fiverr, where I have successfully completed over 700 orders. My extensive experience and expertise make me a valuable asset to any team, and I am confident in my ability to deliver high-quality work, on time and within budget. Trust me to take your project to the next level and exceed your expectations.</p>
                        </div>
                        {/* <!-- Experiences --> */}

                        <div className="experiences main-content">
                            <div className="experiences-details">
                                {/* <!-- Single Experience --> */}
                                <div className="single-experiences">
                                    <h4>Top Rated Seller <br /> at fiverr</h4>
                                    <h5>July 2015 - Now</h5>
                                    <p>As a top-rated seller on Fiverr, I have been hand-picked by the Fiverr team for my exceptional skills and track record of delivering high-quality work. This distinction is not only a testament to my abilities, but also a commitment to maintaining my status through regular evaluations every 2 months. With over 5 years of experience and a proven track record, I am a reliable and highly skilled web developer, capable of delivering outstanding results for my clients.</p>
                                    <a href="https://www.fiverr.com">https://www.fiverr.com</a>
                                </div>
                                {/* <!-- Single Experience --> */}
                                <div className="single-experiences">
                                    <h4>Frontend Web Developer <br /> at Webdesign berlin</h4>
                                    <h5>Jan 2020 - Now</h5>
                                    <p>My responsibilities include coding and implementing user interfaces, optimizing website performance, and ensuring a seamless user experience. I take pride in delivering high-quality code and staying updated with the latest industry trends and best practices in front-end web development.</p>
                                    <a href="https://webdesign-berlin.de/">Webdesign berlin</a>
                                </div>
                                {/* <!-- Single Experience --> */}
                                <div className="single-experiences">
                                    <h4>Head Of Ideas <br /> at ariser.net</h4>
                                    <h5>Jan 2022 - Now</h5>
                                    <p> I am also a Co-founder and Head of Ideas at ariser.net. This role allows me to bring my expertise and passion for web development to the forefront, and lead the company in creating innovative and impactful solutions for our clients. With a strong background in web development, my experience and leadership skills make me a valuable asset in any team and I strive to bring my best to every project, whether it's my own startup or a client's.</p>
                                    <a href="https://www.ariser.net">www.ariser.net</a>
                                </div>
                                {/* <!-- Single Experience --> */}
                                <div className="single-experiences">
                                    <h4>Frontend developer <br /> at techunary</h4>
                                    <h5>April 2020- November 2021</h5>
                                    <p>I have a wealth of experience in the field, including my tenure as a Frontend developer at techunary, where I honed my skills and gained valuable experience working with a wide range of technologies.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Experience